import { ref, watch, computed } from "@vue/composition-api";

// Notification
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useToast } from "vue-toastification/composition";

export default function useCategoriesList() {
  // Use toast
  const { t } = useI18nUtils();
  const toast = useToast();

  const refCategorieListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id" },
    { key: "name", label: t("Name") },
    { key: "active", label: t("Is active") },
  ];
  const perPage = ref(10);
  const totalCategories = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const nameRef = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refCategorieListTable.value
      ? refCategorieListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCategories.value,
    };
  });

  const refetchData = () => {
    refCategorieListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, nameRef], () => {
    refetchData();
  });

  const fetchCategories = (ctx, callback) => {
    instance
      .get("/parameters/subcategories/", {
        params: {
          page: currentPage.value,
          name: nameRef.value,
        },
      })
      .then((response) => {
        const { count, results } = response.data;
        totalCategories.value = count;
        callback(results);
      })
      .catch((err) => {
      });
  };

  return {
    fetchCategories,
    tableColumns,
    perPage,
    currentPage,
    totalCategories,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCategorieListTable,
    refetchData,
    t,
    nameRef,
  };
}
